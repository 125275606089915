import React from 'react';
import { View } from 'react-native';

import Profile from './Profile';
import TopLinks from './forweb/TopLinks';

const App = () => (
  <View>
    <TopLinks/>
    <Profile/>
  </View>
); 
export default App; 